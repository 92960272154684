<template>
    <div class="center-member-tag-nothing">
        <!-- 标题 -->
        <div class="center-member-tag-nothing-title">
            <slot name="title">
            </slot>
        </div>
        <!-- 中间图片 -->
        <div class="center-member-tag-nothing-bg"></div>
        <!-- 底部文字 -->
        <div class="center-member-tag-nothing-tips">
            <slot name="tips">
            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
.center-member-tag-nothing{
    min-height: 440px;
}
/* 标题 */
.center-member-tag-nothing-title{
    color: #909399;
}
/* 背景 */
.center-member-tag-nothing-bg{
    height: 300px;
    margin: 20px 0;
    background-image: url("../assets/images/interface/freelance1.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
/* 底部文字 */
.center-member-tag-nothing-tips{
    text-align: center;
    color: #909399;
}
</style>